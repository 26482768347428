import React, { Component } from 'react'
import { TimelineMax, TweenMax } from 'gsap/TweenMax'
import styled from 'styled-components'
import theme from '../utility/theme'

const StyledBar = styled.div`
  position: relative;
  left: 0;
  z-index: 10;
  width: 70%;
  height: 5px;
  background-color: ${theme.colorPrimary};
`

class Bar extends Component {
  componentDidUpdate(prevProps) {
    if (prevProps.canAnimationStart !== this.props.canAnimationStart) {
      if (this.props.canAnimationStart) {
        this.startAnimation()
      } else {
        this.stopAnimation()
      }
    }
  }

  startAnimation = () => {
    this.bottomBarTimeline = new TimelineMax()
    this.bottomBarTimeline.add(
      TweenMax.from(this.bottomBar, 0.1, {
        xPercent: 0,
        width: '70%',
      })
    )
    this.bottomBarTimeline.add(
      TweenMax.to(this.bottomBar, 0.1, {
        width: '100%',
      })
    )
    this.bottomBarTimeline.add(
      TweenMax.to(this.bottomBar, 0.1, {
        xPercent: 100,
      })
    )
    this.bottomBarTimeline.add(
      TweenMax.from(this.bottomBar, 0.1, {
        xPercent: -100,
        delay: 0.1,
      })
    )
    this.bottomBarTimeline.add(
      TweenMax.to(this.bottomBar, 0.1, {
        width: '100%',
      })
    )
  }

  stopAnimation = () => {
    this.bottomBarTimeline.add(
      TweenMax.to(this.bottomBar, 0.1, {
        width: '70%',
      })
    )
  }

  render() {
    return <StyledBar style={this.props.style} innerRef={div => (this.bottomBar = div)} />
  }
}

export default Bar
