import React, { Component } from 'react'
import styled from 'styled-components'
import { TweenMax } from 'gsap/TweenMax'
import { common } from '../../../utility/common'
import media from '../../../utility/media'
import theme from '../../../utility/theme'

const StyledPageTag = styled.div`
  display: flex;
  margin-bottom: 10px;
  @media (min-width: ${media.md}) {
    margin-bottom: 18px;
  }
`

const PageParent = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
  text-transform: uppercase;
  color: ${theme.colorSecondary};
  @media (min-width: ${media.md}) {
    font-size: 16px;
    letter-spacing: 1.6px;
  }
`

const BorderCenter = styled.div`
  position: relative;
  width: 45px;
  margin: 0 10px;
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 2px;
    background: ${theme.colorGrey};
  }
  @media (min-width: ${media.md}) {
    width: 52px;
  }
`

const CurrentPage = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
  text-transform: uppercase;
  color: ${theme.colorPrimary};
  @media (min-width: ${media.md}) {
    font-size: 16px;
    letter-spacing: 1.6px;
  }
`

class PageTag extends Component {
  componentDidMount() {
    this.pageTagTween = TweenMax.from(this.pageTag, common.duration, {
      autoAlpha: 0,
      x: -25,
      ease: common.easing.easeOut,
      delay: this.props.delay,
    })
  }

  render() {
    const { parentPageName, children } = this.props

    return (
      <StyledPageTag innerRef={div => (this.pageTag = div)}>
        <PageParent>{parentPageName}</PageParent>
        <BorderCenter />
        <CurrentPage>{children}</CurrentPage>
      </StyledPageTag>
    )
  }
}

export default PageTag
