import React, { Component } from 'react'
import styled from 'styled-components'
import theme from '../utility/theme'
import media from '../utility/media'
import Bar from './Bar'

const LinkContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
`

const NavLink = styled.a`
  position: relative;
  left: 0;
  z-index: 15;
  display: block;
  font-family: ${theme.fontFamily};
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 2.8px;
  color: #fff !important;
  text-decoration: none;
  position: relative;
  padding-bottom: 10px;
  @media (min-width: ${media.md}) {
    font-size: 14px;
  }
`

class NavButton extends Component {
  state = {
    canAnimationStart: false,
  }

  onMouseEnterHandler = () => {
    this.setState({
      canAnimationStart: true,
    })
  }

  onMouseLeaveHandler = () => {
    this.setState({
      canAnimationStart: false,
    })
  }

  render() {
    const { path, isOuterLink, onClick, children, ...rest } = this.props
    const { canAnimationStart } = this.state
    return (
      <LinkContainer
        onMouseEnter={this.onMouseEnterHandler}
        onMouseLeave={this.onMouseLeaveHandler}
      >
        {isOuterLink ? (
          <NavLink href={path} target="_blank" {...rest}>
            {children}
          </NavLink>
        ) : (
          <NavLink
            href={path}
            onClick={e => {
              e.preventDefault()
              onClick(path)
            }}
            {...rest}
          >
            {children}
          </NavLink>
        )}

        <Bar
          style={{ marginTop: '-5px' }}
          canAnimationStart={canAnimationStart}
        />
      </LinkContainer>
    )
  }
}

export default NavButton
