import React, { Component } from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import { TimelineMax, TweenMax } from 'gsap/TweenMax'
import { common } from '../utility/common'
import theme from '../utility/theme'
import media from '../utility/media'
import Tag from '../components/page-components/portfolio/TagPortfolioSingle'
import Title from '../components/page-components/Title'
import NavButton from '../components/NavButton'

const Container = styled.div`
  width: 100%;
`

const StyledHeader = styled.div`
  width: 100%;
`

const ProjectContainer = styled.div`
  width: 100%;
  height: ${({ height }) => height}px;
  display: flex;
  justify-content: space-between;
  margin-top: 100px;
  @media (max-width: ${media.lg}) {
    flex-direction: column;
    height: auto;
    margin-top: 50px;
  }
`

const ImageContainer = styled.div`
  width: 55%;
  margin-right: 5%;
  @media (max-width: ${media.lg}) {
    width: 100%;
  }
`

const PreviewImage = styled.img`
  max-width: 100%;
  height: auto;
`

const SummaryContainer = styled.div`
  width: 40%;
  @media (max-width: ${media.lg}) {
    width: 100%;
    margin-top: 50px;
  }
`

const SummaryItem = styled.div`
  margin-bottom: 30px;
`
const SummaryTitle = styled.p`
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 0;
  line-height: 24px;
`

const SummaryText = styled.p`
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: ${theme.colorSecondary};
  margin-bottom: 0;
  line-height: 24px;
`

const DescriptionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  @media (min-width: ${media.md}) {
    padding-top: 50px;
  }
`

const DescriptionItem = styled.div`
  margin-bottom: 50px;
`

const DescriptionTitle = styled.h3`
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  color: #fff;
  @media (min-width: ${media.md}) {
    font-size: 25px;
  }
`
const DescriptionContent = styled.h3`
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: ${theme.colorSecondary};
  margin-bottom: 0;
  line-height: 24px;
  .block-img img{
    max-width: 100%;
  }
`

class PortfolioSingle extends Component {
  state = {
    projectContainerHeight: 350,
  }

  constructor() {
    super()
    this.allSummaryItems = []
    this.allDescriptionItems = []
  }

  componentDidMount() {
    this.setState({
      projectContainerHeight: this.summaryContainer
        ? this.summaryContainer.scrollHeight
        : 'auto',
    })

    // First load
    this.prjectContainerTween = TweenMax.from(
      this.prjectContainer,
      common.duration,
      {
        autoAlpha: 0,
        x: -25,
        ease: common.easing.easeOut,
        delay: this.props.timingOffset + 0.6,
      }
    )

    // Summary items timeline

    this.allSummaryItemsTimeline = new TimelineMax().staggerFrom(
      this.allSummaryItems,
      common.duration,
      {
        x: -15,
        autoAlpha: 0,
        ease: common.easing.easeOut,
        delay: this.props.timingOffset + 0.8,
      },
      common.duration
    )

    // Description items timeline
    this.allSummaryItemsTimeline = new TimelineMax().staggerFrom(
      this.allDescriptionItems,
      common.duration,
      {
        y: 15,
        autoAlpha: 0,
        ease: common.easing.easeOut,
        delay: this.props.timingOffset + 1.6,
      },
      common.duration
    )
  }

  render() {
    const { data, timingOffset } = this.props
    const { projectContainerHeight } = this.state
    const nodeData = data.allPrismicProject.edges[0].node.data
    const summaryItems = nodeData.summary
    const descriptionItems = nodeData.description
    const hasSummary = summaryItems.length > 0

    return (
      <>
        <Helmet>
          <title>{nodeData.title.text}</title>
          <meta name="description" content={nodeData.title.text} />
        </Helmet>
        <Container>
          <StyledHeader>
            <Tag parentPageName="My Works" delay={timingOffset}>
              {nodeData.name.text}
            </Tag>
            <Title delay={timingOffset + 0.3}>{nodeData.title.text}</Title>
          </StyledHeader>
          <ProjectContainer
            height={projectContainerHeight}
            innerRef={div => (this.prjectContainer = div)}
          >
            <ImageContainer>
              <PreviewImage
                src={nodeData.featured_image.url}
                alt="Preview_image"
              />
            </ImageContainer>
            {hasSummary ? (
              <SummaryContainer innerRef={div => (this.summaryContainer = div)}>
                {summaryItems.map((summary, index) => {
                  const title = summary.summary_title.text
                  const text = summary.summary_text.text
                  const live_site = summary.live_site
                  return (
                    <SummaryItem
                      key={index}
                      innerRef={item => (this.allSummaryItems[index] = item)}
                    >
                      {title ? <SummaryTitle>{title}</SummaryTitle> : null}
                      {text ? <SummaryText>{text}</SummaryText> : null}
                      {live_site ? (
                        <NavButton
                          style={{ marginTop: '30px' }}
                          path={live_site.url}
                          isOuterLink
                        >
                          Live Site
                        </NavButton>
                      ) : null}
                    </SummaryItem>
                  )
                })}
              </SummaryContainer>
            ) : null}
          </ProjectContainer>
          <DescriptionContainer>
            {descriptionItems.map((description, index) => {
              const title = description.description_title.text
              const content = description.description_content.html
              return (
                <DescriptionItem
                  key={index}
                  innerRef={item => (this.allDescriptionItems[index] = item)}
                >
                  {title ? <DescriptionTitle>{title}</DescriptionTitle> : null}
                  <DescriptionContent dangerouslySetInnerHTML={{ __html: content }} />
                </DescriptionItem>
              )
            })}
          </DescriptionContainer>
        </Container>
      </>
    )
  }
}

export const query = graphql`
  query($slug: String!) {
    allPrismicProject(filter: { uid: { eq: $slug } }) {
      edges {
        node {
          uid
          data {
            name {
              text
            }
            title {
              text
            }
            featured_image {
              url
            }
            summary {
              summary_title {
                text
              }
              summary_text {
                text
              }
              live_site {
                url
              }
            }
            description {
              description_title {
                text
              }
              description_content {
                html
                text
              }
            }
          }
        }
      }
    }
  }
`

export default PortfolioSingle
